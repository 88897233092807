import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse } from '@app/shared/models/api-response';
import { Macroproject, MacroprojectListItem, MacroprojectPlanning } from '@app/shared/models/macroproject';
import { Project, ProjectTask } from '@app/shared/models/project';
import { Snapshot } from '@app/shared/models/snapshot';
import { SortData } from '@app/shared/models/sort-data';

@Injectable({
    providedIn: 'root',
})
export class SnapshotsService {
    constructor(private httpClient: HttpClient) {}

    getSnapshots(): Observable<Snapshot[]> {
        const url = 'items/snapshots';

        return this.httpClient.get<ApiResponse<Snapshot[]>>(url).pipe(map((response: ApiResponse<Snapshot[]>) => response.data));
    }

    createSnapshot(data: Record<string, string>): Observable<Snapshot> {
        const url = 'snapshots/create';

        return this.httpClient.post<ApiResponse<Snapshot>>(url, data).pipe(map((response: ApiResponse<Snapshot>) => response.data));
    }

    updateSnapshot(id: number, data: Record<string, string>): Observable<Snapshot> {
        const url = `items/snapshots/${id}`;

        return this.httpClient.patch<ApiResponse<Snapshot>>(url, data).pipe(map((response: ApiResponse<Snapshot>) => response.data));
    }

    //
    // ─── SNAPSHOT RESOURCES ─────────────────────────────────────────────────────────
    //

    // TODO: evaluate snapshotId inside filters object
    getMacroprojectsSnapshots(snapshotId: number, sort?: SortData): Observable<MacroprojectListItem[]> {
        const filters = { snapshot: snapshotId };
        const sortBy = sort ? `&sort=${sort.direction === 'ASC' ? '' : '-'}${sort.by}` : '';
        const url = `items/macro_projects_snapshots?filter=${JSON.stringify(filters)}${sortBy}`;

        return this.httpClient
            .get<ApiResponse<MacroprojectListItem[]>>(url)
            .pipe(map((response: ApiResponse<MacroprojectListItem[]>) => response.data));
    }

    getMacroprojectsSnapshotsFull(filters: Record<string, unknown> = {}, sort?: SortData): Observable<Macroproject[]> {
        const sortBy = sort ? `&sort=${sort.direction === 'ASC' ? '' : '-'}${sort.by}` : '';
        const url = `items/macro_projects_snapshots?fields=*.*.*&filter=${JSON.stringify(filters)}${sortBy}`;

        return this.httpClient.get<ApiResponse<Macroproject[]>>(url).pipe(map((response: ApiResponse<Macroproject[]>) => response.data));
    }

    getMacroprojectSnapshot(id: number, snapshotId: number): Observable<Macroproject> {
        const filters = { snapshot: snapshotId };
        const url = `items/macro_projects_snapshots/${id}?fields=*.*.*&filter=${JSON.stringify(filters)}`;

        return this.httpClient.get<ApiResponse<Macroproject>>(url).pipe(map((response: ApiResponse<Macroproject>) => response.data));
    }

    getMacroprojectPlanningsSnapshots(snapshotId: number, filters: Record<string, unknown> = {}): Observable<MacroprojectPlanning[]> {
        const url = `items/macro_project_plannings_snapshots?filter=${JSON.stringify(filters)}`;
        filters = {
            _and: [{ ...filters }, { snapshot: snapshotId }],
        };

        return this.httpClient
            .get<ApiResponse<MacroprojectPlanning[]>>(url)
            .pipe(map((response: ApiResponse<MacroprojectPlanning[]>) => response.data));
    }

    getProjectsSnapshots(snapshotId: number, filters: Record<string, unknown> = {}, sort?: SortData): Observable<Project[]> {
        const sortBy = sort ? `&sort=${sort.direction === 'ASC' ? '' : '-'}${sort.by}` : '';
        const url = `items/projects_snapshots?fields=*.*&filter=${JSON.stringify(filters)}${sortBy}`;
        filters = {
            _and: [{ ...filters }, { snapshot: snapshotId }],
        };

        return this.httpClient.get<ApiResponse<Project[]>>(url).pipe(map((response: ApiResponse<Project[]>) => response.data));
    }

    getProjectTasksSnapshots(snapshotId: number, filters: Record<string, unknown> = {}): Observable<ProjectTask[]> {
        const url = `items/project_tasks_snapshots?filter=${JSON.stringify(filters)}`;
        filters = {
            _and: [{ ...filters }, { snapshot: snapshotId }],
        };

        return this.httpClient.get<ApiResponse<ProjectTask[]>>(url).pipe(map((response: ApiResponse<ProjectTask[]>) => response.data));
    }
}
