import { Pipe, PipeTransform } from '@angular/core';

import { MacroprojectClusterCategory } from '../models/macroproject';

@Pipe({
    name: 'filterByCluster',
})
export class CategoryFilterByClusterPipe implements PipeTransform {
    transform(categories: MacroprojectClusterCategory[], selectedCluster: number | null | undefined): any {
        if (!selectedCluster) return categories;
        return categories.filter((category: MacroprojectClusterCategory) => {
            return category.cluster.id === selectedCluster;
        });
    }
}
