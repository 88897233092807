<h1 mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content>
    <p class="ppm-confirmation__text">
        {{ data.body }}
    </p>
    <p class="ppm-confirmation__highlight" *ngIf="data.highlightText">
        {{ data.highlightText }}
    </p>
</div>

<div mat-dialog-actions>
    <button mat-stroked-button type="button" color="primary" mat-dialog-close>CANCEL</button>
    <button mat-flat-button type="button" color="primary" (click)="onConfirm()">OK</button>
</div>
