import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { finalize } from 'rxjs';

import { AuthService } from '@app/core/services/base/auth.service';

import { User } from '@app/shared/models/user';

@Component({
    selector: 'ppm-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public loggedUser!: User;
    public navLinks = [
        { linkName: 'Dashboard', pageUrl: 'dashboard' },
        { linkName: 'Planner', pageUrl: 'planner' },
        { linkName: 'Administration', pageUrl: 'administration' },
    ];

    constructor(private authService: AuthService, private router: Router) {}

    ngOnInit(): void {
        this.getLoggedUser();
    }

    getLoggedUser(): void {
        this.authService.getLoggedUser().subscribe((response: User) => (this.loggedUser = response));
    }

    onLogout(): void {
        this.authService
            .logout()
            .pipe(finalize(() => this.router.navigateByUrl('/auth/login')))
            .subscribe();
    }
}
