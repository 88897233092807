import { differenceInMinutes, fromUnixTime } from 'date-fns';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { ApiResponse } from '@app/shared/models/api-response';
import { AuthResponse } from '@app/shared/models/auth-response';
import { User } from '@app/shared/models/user';

import { LocalStorageManagementService } from './local-storage-management.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private httpClient: HttpClient, private localStorageService: LocalStorageManagementService) {}

    logout(): Observable<null> {
        const url = 'auth/logout';

        return this.httpClient.post<null>(url, {}, { withCredentials: true }).pipe(
            finalize(() => {
                this.localStorageService.deleteTokens();
                this.localStorageService.deleteUser();
            })
        );
    }

    refreshToken(): Observable<ApiResponse<AuthResponse>> {
        const url = `auth/refresh`;
        const refreshToken = this.localStorageService.getRefreshToken();
        const params = refreshToken ? { refresh_token: refreshToken } : {};

        return this.httpClient.post<ApiResponse<AuthResponse>>(url, params, { withCredentials: true }).pipe(
            tap((response: ApiResponse<AuthResponse>) => {
                this.localStorageService.setToken(response.data.access_token);
                if (response.data.refresh_token) {
                    this.localStorageService.setRefreshToken(response.data.refresh_token);
                }
                // this.getUser().subscribe();
            })
        );
    }

    isTokenExpired(token?: string): boolean {
        const authToken = token || this.localStorageService.getToken();

        if (!authToken) {
            return true;
        }

        const date = this.getTokenExpirationDate(authToken);

        if (!date) {
            return true;
        }

        return differenceInMinutes(date, new Date()) <= 0;
    }

    getTokenExpirationDate(token: string): Date | null {
        try {
            const decoded = jwtDecode<JwtPayload>(token);

            if (decoded.exp === undefined) {
                return null;
            }

            return fromUnixTime(decoded.exp);
        } catch (error) {
            return null;
        }
    }

    getLoggedUser(): Observable<User> {
        const url = 'users/me';

        return this.httpClient.get<ApiResponse<User>>(url).pipe(map((response: ApiResponse<User>) => response.data));
    }
}
