import { AgGridModule } from 'ag-grid-angular';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MaterialModule } from '@app/material/material.module';

import { AgButtonRendererComponent } from './ag-grid-utils/renderers/ag-button-renderer/ag-button-renderer.component';
import { ClusterFormDialogComponent } from './components/cluster-form-dialog/cluster-form-dialog.component';
import { ClusterGridComponent } from './components/cluster-grid/cluster-grid.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { MacroprojectFormDialogComponent } from './components/macroproject-form-dialog/macroproject-form-dialog.component';
import { ProjectFormDialogComponent } from './components/project-form-dialog/project-form-dialog.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { PageLayoutComponent } from './layouts/page-layout/page-layout.component';
import { CategoryFilterByClusterPipe } from './pipes/category-filter-by-cluster.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { UserFullNamePipe } from './pipes/user-full-name.pipe';

@NgModule({
    declarations: [
        AuthLayoutComponent,
        PageLayoutComponent,
        HeaderComponent,
        ProjectFormDialogComponent,
        AgButtonRendererComponent,
        ClusterGridComponent,
        UserFullNamePipe,
        CategoryFilterByClusterPipe,
        OrderByPipe,
        MacroprojectFormDialogComponent,
        ConfirmationDialogComponent,
        ClusterFormDialogComponent,
    ],
    imports: [CommonModule, RouterModule, AgGridModule.withComponents(), FormsModule, ReactiveFormsModule, MaterialModule],
    exports: [
        AuthLayoutComponent,
        PageLayoutComponent,
        ProjectFormDialogComponent,
        AgButtonRendererComponent,
        ClusterGridComponent,
        UserFullNamePipe,
        CategoryFilterByClusterPipe,
        OrderByPipe,
    ],
})
export class SharedModule {}
