<mat-toolbar class="ppm-header">
    <img class="ppm-header__logo" src="assets/images/logo.png" alt="Ducati Logo" />
    <span>Ducati PPM</span>
    <span class="ppm-header__spacer"></span>
    <div class="ppm-header__actions">
        {{ loggedUser | userFullName }}
        <button mat-icon-button [matMenuTriggerFor]="headerMenu" aria-label="Open header menu">
            <mat-icon>expand_more</mat-icon>
        </button>
        <mat-menu #headerMenu="matMenu">
            <button mat-menu-item (click)="onLogout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>
    </div>
</mat-toolbar>

<nav mat-tab-nav-bar mat-stretch-tabs backgroundColor="primary">
    <a
        mat-tab-link
        *ngFor="let link of navLinks"
        [routerLink]="link.pageUrl"
        routerLinkActive
        #routerLink="routerLinkActive"
        [active]="routerLink.isActive"
    >
        {{ link.linkName | uppercase }}
    </a>
</nav>
