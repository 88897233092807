import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { MacroprojectsService } from '@app/core/services/macroprojects.service';

import {
    Macroproject,
    MacroprojectCluster,
    MacroprojectClusterCategory,
    MacroprojectRiskLevel,
    MacroprojectRiskMitigation,
    MacroprojectRoadmap,
    MacroprojectTypology,
} from '@app/shared/models/macroproject';

@Component({
    selector: 'ppm-macroproject-form-dialog',
    templateUrl: './macroproject-form-dialog.component.html',
    styleUrls: ['./macroproject-form-dialog.component.scss'],
})
export class MacroprojectFormDialogComponent implements OnInit {
    public macroprojectForm!: FormGroup;
    public macroprojectRoadmaps: MacroprojectRoadmap[] = [];
    public macroprojectRiskMitigations: MacroprojectRiskMitigation[] = [];
    public macroprojectRiskLevels: MacroprojectRiskLevel[] = [];
    public macroprojectClusters: MacroprojectCluster[] = [];
    public macroprojectTypologies: MacroprojectTypology[] = [];
    public macroprojectClusterCategories: MacroprojectClusterCategory[] = [];
    public filteredMacroprojectClusterCategories: MacroprojectClusterCategory[] = [];

    constructor(
        private dialogRef: MatDialogRef<MacroprojectFormDialogComponent>,
        private formBuilder: FormBuilder,
        private macroprojectsService: MacroprojectsService
    ) {}

    get clusterField(): FormControl {
        return this.macroprojectForm.get('cluster') as FormControl;
    }

    get clusterCategoryField(): FormControl {
        return this.macroprojectForm.get('cluster_category') as FormControl;
    }

    get macroprojectTypologyField(): FormControl {
        return this.macroprojectForm.get('typology') as FormControl;
    }

    ngOnInit(): void {
        this.getMacroprojectRoadmaps();
        this.getMacroprojectRiskMitigations();
        this.getMacroprojectRiskLevels();
        this.getMacroprojectClusters();
        this.getMacroprojectClusterCategories();
        this.getMacroProjectTypologies();
        this.createForm();
    }

    getMacroprojectRoadmaps(): void {
        this.macroprojectsService
            .getMacroprojectRoadmaps()
            .subscribe((response: MacroprojectRoadmap[]) => (this.macroprojectRoadmaps = response));
    }

    getMacroprojectRiskMitigations(): void {
        this.macroprojectsService
            .getRiskMitigations()
            .subscribe((response: MacroprojectRiskMitigation[]) => (this.macroprojectRiskMitigations = response));
    }

    getMacroprojectRiskLevels(): void {
        this.macroprojectsService
            .getRiskMitigationLevels()
            .subscribe((response: MacroprojectRiskLevel[]) => (this.macroprojectRiskLevels = response));
    }

    getMacroprojectClusters(): void {
        this.macroprojectsService.getClusters().subscribe((response: MacroprojectCluster[]) => (this.macroprojectClusters = response));
    }

    getMacroProjectTypologies(): void {
        this.macroprojectsService
            .getMacroprojectTypologies()
            .subscribe((response: MacroprojectTypology[]) => (this.macroprojectTypologies = response));
    }

    getMacroprojectClusterCategories(): void {
        this.macroprojectsService
            .getClusterCategories()
            .subscribe(
                (response: MacroprojectClusterCategory[]) =>
                    (this.macroprojectClusterCategories = this.filteredMacroprojectClusterCategories = response)
            );
    }

    createForm(): void {
        const { required } = Validators;

        this.macroprojectForm = this.formBuilder.group({
            roadmap: [null, required],
            name: [null, required],
            // stream: [null],
            risk_mitigation: [null],
            risk_level: [null],
            cluster: [null],
            cluster_category: [null],
            typology: [null],
            wbe: [null],
        });
    }

    onClusterSelected(clusterId: number | null): void {
        this.filteredMacroprojectClusterCategories =
            clusterId === null
                ? this.macroprojectClusterCategories
                : this.macroprojectClusterCategories.filter((category: MacroprojectClusterCategory) => category.cluster.id === clusterId);

        this.clusterCategoryField?.reset();
        this.clusterCategoryField?.updateValueAndValidity();
    }

    onClusterCategorySelected(clusterCategoryId: number | null): void {
        const selectedClusterCategory = this.macroprojectClusterCategories.find(
            (category: MacroprojectClusterCategory) => category.id === clusterCategoryId
        );

        if (selectedClusterCategory) {
            const relatedClusterId =
                this.macroprojectClusters.find((cluster: MacroprojectCluster) => cluster.id === selectedClusterCategory.cluster.id)?.id ??
                null;
            this.clusterField?.setValue(relatedClusterId);
            this.clusterField?.updateValueAndValidity();
            this.filteredMacroprojectClusterCategories = this.macroprojectClusterCategories.filter(
                (category: MacroprojectClusterCategory) => category.cluster.id === relatedClusterId
            );
        }
    }

    onSubmit(): void {
        if (this.macroprojectForm.valid) {
            const payload = this.macroprojectForm.value;

            this.macroprojectsService.createMacroproject(payload).subscribe((response: Macroproject) => this.dialogRef.close(response));
        }
    }
}
