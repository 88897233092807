import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let apiRequest;

        if (request.url.includes('i18n') || request.url.includes('settings.json') || request.url.includes('assets/docs/')) {
            apiRequest = request;
        } else {
            const params =
                request.method === 'GET' && !request.url.includes('dashboard')
                    ? (request.params ?? new HttpParams()).set('limit', -1)
                    : request.params;
            apiRequest = request.clone({
                url: `${environment.apiUrl}/${request.url}`,
                params: params,
            });
        }

        return next.handle(apiRequest);
    }
}
