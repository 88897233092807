<form class="ppm-macroprojectForm__wrapper" [formGroup]="macroprojectForm" (ngSubmit)="onSubmit()">
    <h1 mat-dialog-title>New Macroproject</h1>

    <div mat-dialog-content>
        <div class="ppm-macroprojectForm__fields">
            <mat-form-field appearance="outline">
                <mat-label>Roadmap</mat-label>
                <mat-select formControlName="roadmap">
                    <mat-option *ngFor="let roadmap of macroprojectRoadmaps" [value]="roadmap.id">
                        {{ roadmap.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="macroprojectForm.get('roadmap')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Macroproject name</mat-label>
                <input matInput formControlName="name" />
                <mat-error *ngIf="macroprojectForm.get('name')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <!-- <mat-form-field appearance="outline">
                <mat-label>Stream</mat-label>
                <input matInput formControlName="stream" />
                <mat-error *ngIf="macroprojectForm.get('stream')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field> -->

            <!-- <mat-form-field appearance="outline">
                <mat-label>Tipology</mat-label>
                <mat-select formControlName="typology">
                    <mat-option [value]="null"> - None - </mat-option>
                    <mat-option *ngFor="let tipology of macroprojectTypologies" [value]="tipology.id">
                        {{ tipology.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="macroprojectForm.get('priority')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field> -->

            <mat-form-field appearance="outline">
                <mat-label>WBE</mat-label>
                <input matInput formControlName="wbe" />
                <mat-error *ngIf="macroprojectForm.get('wbe')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Risk mitigation</mat-label>
                <mat-select formControlName="risk_mitigation">
                    <mat-option [value]="null"> - None - </mat-option>
                    <mat-option *ngFor="let riskMitigation of macroprojectRiskMitigations" [value]="riskMitigation.id">
                        {{ riskMitigation.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="macroprojectForm.get('risk_mitigation')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Risk mitigation level</mat-label>
                <mat-select formControlName="risk_level">
                    <mat-option [value]="null"> - None - </mat-option>
                    <mat-option *ngFor="let riskLevel of macroprojectRiskLevels" [value]="riskLevel.id">
                        {{ riskLevel.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="macroprojectForm.get('risk_level')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <!-- <mat-form-field appearance="outline">
                <mat-label>Cluster</mat-label>
                <mat-select formControlName="cluster" (valueChange)="onClusterSelected($event)">
                    <mat-option [value]="null"> - None - </mat-option>
                    <mat-option *ngFor="let cluster of macroprojectClusters" [value]="cluster.id">
                        {{ cluster.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="macroprojectForm.get('priority')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field> -->

            <!-- <mat-form-field appearance="outline">
                <mat-label>Subcategory</mat-label>
                <mat-select formControlName="cluster_category" (valueChange)="onClusterCategorySelected($event)">
                    <mat-option [value]="null"> - None - </mat-option>
                    <mat-option *ngFor="let priority of filteredMacroprojectClusterCategories" [value]="priority.id">
                        {{ priority.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="macroprojectForm.get('cluster_category')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field> -->
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-stroked-button type="button" color="primary" mat-dialog-close>CANCEL</button>
        <button mat-flat-button type="submit" color="primary">SAVE</button>
    </div>
</form>
