import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    constructor() {}

    removeUndefinedFromObject(object: Record<string, unknown>): Record<string, unknown> {
        Object.keys(object).forEach((key: string) => (object[key] === undefined || object[key] === '' ? delete object[key] : ''));
        return object;
    }

    removeUndefinedAndNullFromObject(object: Record<string, unknown>): Record<string, unknown> {
        Object.keys(object).forEach((key: string) =>
            object[key] === undefined || object[key] === null || object[key] === '' ? delete object[key] : ''
        );
        return object;
    }
}
