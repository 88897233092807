<div class="ppm-clusterGrid__actions">
    <button mat-button color="primary" (click)="onExportAsCsv()">
        <mat-icon>download</mat-icon>
        Download
    </button>
</div>

<div class="ppm-clusterGrid__table">
    <ag-grid-angular
        class="ag-theme-alpine ppm-ag-readonly"
        domLayout="autoHeight"
        [headerHeight]="32"
        [rowHeight]="32"
        [suppressMovableColumns]="true"
        [columnDefs]="overviewColumnDefs"
        [rowData]="overviewRowData"
        [pinnedBottomRowData]="overviewRowTotal"
        [defaultColDef]="defaultColDef"
        [pagination]="true"
        [paginationPageSize]="pageSize"
        (rowDoubleClicked)="onEditRowDetails($event)"
        (filterChanged)="setTotalsRow()"
        (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
</div>
