import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CompaniesService } from '@app/core/services/companies.service';
import { ProjectsService } from '@app/core/services/projects.service';
import { UsersService } from '@app/core/services/users.service';

import { Company } from '@app/shared/models/company';
import { Project, ProjectBoardArea, ProjectBusinessArea, ProjectTypology } from '@app/shared/models/project';
import { User } from '@app/shared/models/user';

@Component({
    selector: 'ppm-project-form-dialog',
    templateUrl: './project-form-dialog.component.html',
    styleUrls: ['./project-form-dialog.component.scss'],
})
export class ProjectFormDialogComponent implements OnInit {
    public projectForm!: FormGroup;
    public projectBusinessAreas: ProjectBusinessArea[] = [];
    public projectBoardAreas: ProjectBoardArea[] = [];
    public companies: Company[] = [];
    public users: User[] = [];

    constructor(
        private companiesService: CompaniesService,
        private dialogRef: MatDialogRef<ProjectFormDialogComponent>,
        private formBuilder: FormBuilder,
        private projectsService: ProjectsService,
        private usersService: UsersService,
        @Inject(MAT_DIALOG_DATA) private dialogData: { macroprojectId: number }
    ) {}

    ngOnInit(): void {
        this.getProjectBusinessAreas();
        this.getProjectBoardAreas();
        this.getCompanies();
        this.createForm();
    }

    getProjectBusinessAreas(): void {
        this.projectsService
            .getProjectBusinessAreas()
            .subscribe((response: ProjectBusinessArea[]) => (this.projectBusinessAreas = response));
    }

    getProjectBoardAreas(): void {
        this.projectsService.getProjectBoardAreas().subscribe((response: ProjectBoardArea[]) => (this.projectBoardAreas = response));
    }

    getCompanies(): void {
        this.companiesService.getCompanies().subscribe((response: Company[]) => (this.companies = response));
    }

    createForm(): void {
        const { required } = Validators;

        this.projectForm = this.formBuilder.group({
            name: [null, required],
            macro_project: [this.dialogData.macroprojectId],
            business_area: [null, required],
            // board_area: [null],
            company: [null, required],
            requestor: [null, required],
            responsible: [null, required],
        });
    }

    onSubmit(): void {
        if (this.projectForm.valid) {
            const data = this.projectForm.value;

            this.projectsService.createProject(data).subscribe((response: Project) => this.dialogRef.close(response));
        }
    }
}
