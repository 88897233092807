import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable, Subject, tap } from 'rxjs';

import { ApiResponse } from '@app/shared/models/api-response';
import {
    Macroproject,
    MacroprojectCluster,
    MacroprojectClusterCategory,
    MacroprojectListItem,
    MacroprojectPlanning,
    MacroprojectPriority,
    MacroprojectRiskLevel,
    MacroprojectRiskMitigation,
    MacroprojectRoadmap,
    MacroprojectTypology,
} from '@app/shared/models/macroproject';
import { SortData } from '@app/shared/models/sort-data';

import { UtilsService } from './base/utils.service';

@Injectable({
    providedIn: 'root',
})
export class MacroprojectsService {
    private macroprojectUpdatedSource = new Subject<Macroproject>();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public macroprojectUpdated$ = this.macroprojectUpdatedSource.asObservable();

    constructor(private httpClient: HttpClient, private utilsService: UtilsService) {}

    //
    // ─── HTTP REQUESTS ──────────────────────────────────────────────────────────────
    //

    getMacroprojects(filters: Record<string, unknown> = {}, sort?: SortData): Observable<MacroprojectListItem[]> {
        const sortBy = sort ? `&sort=${sort.direction === 'ASC' ? '' : '-'}${sort.by}` : '';
        const url = `items/macro_projects?filter=${JSON.stringify(filters)}${sortBy}`;

        return this.httpClient
            .get<ApiResponse<MacroprojectListItem[]>>(url)
            .pipe(map((response: ApiResponse<MacroprojectListItem[]>) => response.data));
    }

    getMacroprojectsFull(filters: Record<string, unknown> = {}, sort?: SortData): Observable<Macroproject[]> {
        const sortBy = sort ? `&sort=${sort.direction === 'ASC' ? '' : '-'}${sort.by}` : '';
        const url = `items/macro_projects?fields=*.*.*&filter=${JSON.stringify(filters)}${sortBy}`;

        return this.httpClient.get<ApiResponse<Macroproject[]>>(url).pipe(map((response: ApiResponse<Macroproject[]>) => response.data));
    }

    getMacroproject(id: number): Observable<Macroproject> {
        const url = `items/macro_projects/${id}?fields=*.*.*`;

        return this.httpClient.get<ApiResponse<Macroproject>>(url).pipe(map((response: ApiResponse<Macroproject>) => response.data));
    }

    createMacroproject(data: Record<string, unknown>): Observable<Macroproject> {
        const url = 'items/macro_projects?fields=*.*';

        return this.httpClient.post<ApiResponse<Macroproject>>(url, data).pipe(map((response: ApiResponse<Macroproject>) => response.data));
    }

    updateMacroproject(id: number, data: Record<string, unknown>): Observable<Macroproject> {
        const url = `items/macro_projects/${id}?fields=*.*.*`;

        return this.httpClient.patch<ApiResponse<Macroproject>>(url, data).pipe(
            map((response: ApiResponse<Macroproject>) => response.data),
            tap((macroproject: Macroproject) => this.macroprojectUpdatedSource.next(macroproject))
        );
    }

    deleteMacroproject(id: number): Observable<null> {
        const url = `items/macro_projects/${id}`;

        return this.httpClient.delete<null>(url);
    }

    lockMacroproject(id: number): Observable<Macroproject> {
        const url = `macro_projects/lock/${id}`;

        return this.httpClient.patch<ApiResponse<Macroproject>>(url, {}).pipe(map((response: ApiResponse<Macroproject>) => response.data));
    }

    unlockMacroproject(id: number): Observable<Macroproject> {
        const url = `macro_projects/unlock/${id}`;

        return this.httpClient.patch<ApiResponse<Macroproject>>(url, {}).pipe(map((response: ApiResponse<Macroproject>) => response.data));
    }

    getMacroprojectPlannings(filters: Record<string, unknown> = {}): Observable<MacroprojectPlanning[]> {
        const url = `items/macro_project_plannings?filter=${JSON.stringify(filters)}`;

        return this.httpClient
            .get<ApiResponse<MacroprojectPlanning[]>>(url)
            .pipe(map((response: ApiResponse<MacroprojectPlanning[]>) => response.data));
    }

    createMacroprojectPlanning(data: Record<string, unknown>): Observable<MacroprojectPlanning> {
        const url = 'items/macro_project_plannings';

        return this.httpClient
            .post<ApiResponse<MacroprojectPlanning>>(url, data)
            .pipe(map((response: ApiResponse<MacroprojectPlanning>) => response.data));
    }

    updateMacroprojectPlanning(id: number, data: Record<string, unknown>): Observable<MacroprojectPlanning> {
        const url = `items/macro_project_plannings/${id}`;

        return this.httpClient
            .patch<ApiResponse<MacroprojectPlanning>>(url, data)
            .pipe(map((response: ApiResponse<MacroprojectPlanning>) => response.data));
    }

    getMacroprojectRoadmaps(): Observable<MacroprojectRoadmap[]> {
        const url = 'items/macro_project_roadmaps?sort=name';

        return this.httpClient
            .get<ApiResponse<MacroprojectRoadmap[]>>(url)
            .pipe(map((response: ApiResponse<MacroprojectRoadmap[]>) => response.data));
    }

    getMacroprojectRoadmap(id: number): Observable<MacroprojectRoadmap> {
        const url = `items/macro_project_roadmaps/${id}?fields=*.*`;

        return this.httpClient
            .get<ApiResponse<MacroprojectRoadmap>>(url)
            .pipe(map((response: ApiResponse<MacroprojectRoadmap>) => response.data));
    }

    getRiskMitigations(): Observable<MacroprojectRiskMitigation[]> {
        const url = 'items/macro_project_risk_mitigations?sort=name';

        return this.httpClient
            .get<ApiResponse<MacroprojectRiskMitigation[]>>(url)
            .pipe(map((response: ApiResponse<MacroprojectRiskMitigation[]>) => response.data));
    }

    getRiskMitigationLevels(): Observable<MacroprojectRiskLevel[]> {
        const url = 'items/macro_project_risk_levels?sort=name';

        return this.httpClient
            .get<ApiResponse<MacroprojectRiskLevel[]>>(url)
            .pipe(map((response: ApiResponse<MacroprojectRiskLevel[]>) => response.data));
    }

    getPriorities(): Observable<MacroprojectPriority[]> {
        const url = 'items/macro_project_priorities?sort=name';

        return this.httpClient
            .get<ApiResponse<MacroprojectPriority[]>>(url)
            .pipe(map((response: ApiResponse<MacroprojectPriority[]>) => response.data));
    }

    getClusters(): Observable<MacroprojectCluster[]> {
        const url = 'items/macro_project_clusters?sort=name';

        return this.httpClient
            .get<ApiResponse<MacroprojectCluster[]>>(url)
            .pipe(map((response: ApiResponse<MacroprojectCluster[]>) => response.data));
    }

    getMacroprojectTypologies(): Observable<MacroprojectTypology[]> {
        const url = 'items/macro_project_typologies?sort=name';
        return this.httpClient.get<ApiResponse<MacroprojectTypology[]>>(url).pipe(map((response: ApiResponse<any[]>) => response.data));
    }

    getClusterCategories(): Observable<MacroprojectClusterCategory[]> {
        const url = 'items/macro_project_cluster_categories?fields=*.*&sort=name';

        return this.httpClient
            .get<ApiResponse<MacroprojectClusterCategory[]>>(url)
            .pipe(map((response: ApiResponse<MacroprojectClusterCategory[]>) => response.data));
    }

    getRoadmapRecapData(yearStart: number, snapshot?: number): Observable<any> {
        const url = 'macro_projects/dashboard';
        const params = this.utilsService.removeUndefinedAndNullFromObject({
            year: yearStart.toString(),
            snapshot: snapshot?.toString(),
        }) as Record<string, string>;

        return this.httpClient.get<any>(url, { params });
    }
}
