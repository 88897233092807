import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ApiResponse } from '@app/shared/models/api-response';
import { Company } from '@app/shared/models/company';

@Injectable({
    providedIn: 'root',
})
export class CompaniesService {
    constructor(private httpClient: HttpClient) {}

    getCompanies(): Observable<Company[]> {
        const url = 'items/companies?fields=*.*&sort=name';

        return this.httpClient.get<ApiResponse<Company[]>>(url).pipe(map((response: ApiResponse<Company[]>) => response.data));
    }
}
