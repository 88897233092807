import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { LoggedInGuard } from './core/guards/logged-in.guard';
import { AuthLayoutComponent } from './shared/layouts/auth-layout/auth-layout.component';
import { PageLayoutComponent } from './shared/layouts/page-layout/page-layout.component';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: '',
        component: AuthLayoutComponent,
        canActivate: [LoggedInGuard],
        children: [
            {
                path: 'auth',
                loadChildren: () =>
                    import('./features/auth/auth.module').then((m: typeof import('./features/auth/auth.module')) => m.AuthModule),
            },
        ],
    },
    {
        path: '',
        component: PageLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('./features/dashboard/dashboard.module').then(
                        (m: typeof import('./features/dashboard/dashboard.module')) => m.DashboardModule
                    ),
            },
            {
                path: 'planner',
                loadChildren: () =>
                    import('./features/planner/planner.module').then(
                        (m: typeof import('./features/planner/planner.module')) => m.PlannerModule
                    ),
            },
            {
                path: 'administration',
                loadChildren: () =>
                    import('./features/reporting/reporting.module').then(
                        (m: typeof import('./features/reporting/reporting.module')) => m.ReportingModule
                    ),
            },
        ],
    },
    { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
