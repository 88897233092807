import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ApiResponse } from '@app/shared/models/api-response';
import { User } from '@app/shared/models/user';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(private httpClient: HttpClient) {}

    getUsers(): Observable<User[]> {
        const url = 'users';

        return this.httpClient.get<ApiResponse<User[]>>(url).pipe(map((response: ApiResponse<User[]>) => response.data));
    }
}
