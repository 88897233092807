<form class="ppm-projectForm__wrapper" [formGroup]="projectForm" (ngSubmit)="onSubmit()">
    <h1 mat-dialog-title>New Project</h1>

    <div mat-dialog-content>
        <div class="ppm-projectForm__fields">
            <mat-form-field appearance="outline">
                <mat-label>Project name</mat-label>
                <input matInput formControlName="name" />
                <mat-error *ngIf="projectForm.get('name')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Business Area</mat-label>
                <mat-select formControlName="business_area">
                    <mat-option *ngFor="let businessArea of projectBusinessAreas | orderBy: 'name'" [value]="businessArea.id">
                        {{ businessArea.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="projectForm.get('business_area')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <!-- <mat-form-field appearance="outline">
                <mat-label>Board Area</mat-label>
                <mat-select formControlName="board_area">
                    <mat-option *ngFor="let boardArea of projectBoardAreas" [value]="boardArea.id">
                        {{ boardArea.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->

            <mat-form-field appearance="outline">
                <mat-label>Company</mat-label>
                <mat-select formControlName="company">
                    <mat-option *ngFor="let company of companies" [value]="company.id">
                        {{ company.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="projectForm.get('company')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Requestor</mat-label>
                <input matInput formControlName="requestor" />
                <mat-error *ngIf="projectForm.get('requestor')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>IT Responsible</mat-label>
                <input matInput formControlName="responsible" />
                <mat-error *ngIf="projectForm.get('responsible')?.invalid">Campo obbligatorio</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div mat-dialog-actions>
        <button mat-stroked-button type="button" color="primary" mat-dialog-close>CANCEL</button>
        <button mat-flat-button type="submit" color="primary">SAVE</button>
    </div>
</form>
