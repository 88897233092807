import { Pipe, PipeTransform } from '@angular/core';

import { User } from '../models/user';

@Pipe({
    name: 'userFullName',
})
export class UserFullNamePipe implements PipeTransform {
    transform(user: User | null | undefined): unknown {
        return user ? `${user.first_name} ${user.last_name}` : '';
    }
}
