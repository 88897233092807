import { ValueFormatterParams } from 'ag-grid-community';

export function agNumberFormatter(params: ValueFormatterParams): string {
    if (params.value === null || params.value === undefined || params.value === '') {
        return '';
    }

    // if number is whole convert to string, if it has decimals round it up and replace decimal separator
    const value = +params.value % 1 === 0 ? `${params.value}` : (+params.value).toFixed(2).replace('.', ',');

    // add thousands separator (i'm just gonna trust this regexp i found on stack overflow)
    // https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
    const valueFormatted = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return valueFormatted;
}
