import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiResponse } from '@app/shared/models/api-response';
import { Project, ProjectBoardArea, ProjectBusinessArea, ProjectTask, ProjectTypology } from '@app/shared/models/project';
import { SortData } from '@app/shared/models/sort-data';

@Injectable({
    providedIn: 'root',
})
export class ProjectsService {
    constructor(private httpClient: HttpClient) {}

    getProjects(filters: Record<string, unknown> = {}, sort?: SortData): Observable<Project[]> {
        const sortBy = sort ? `&sort=${sort.direction === 'ASC' ? '' : '-'}${sort.by}` : '';
        const url = `items/projects?fields=*.*&filter=${JSON.stringify(filters)}${sortBy}`;

        return this.httpClient.get<ApiResponse<Project[]>>(url).pipe(map((response: ApiResponse<Project[]>) => response.data));
    }

    createProject(data: Record<string, unknown>): Observable<Project> {
        const url = `items/projects?fields=*.*`;

        return this.httpClient.post<ApiResponse<Project>>(url, data).pipe(map((response: ApiResponse<Project>) => response.data));
    }

    updateProject(projectId: number, data: Record<string, unknown>): Observable<Project> {
        const url = `items/projects/${projectId}?fields=*.*`;

        return this.httpClient.patch<ApiResponse<Project>>(url, data).pipe(map((response: ApiResponse<Project>) => response.data));
    }

    deleteProject(id: number): Observable<null> {
        const url = `items/projects/${id}`;

        return this.httpClient.delete<null>(url);
    }

    getProjectTasks(filters: Record<string, unknown> = {}): Observable<ProjectTask[]> {
        const url = `items/project_tasks?filter=${JSON.stringify(filters)}`;

        return this.httpClient.get<ApiResponse<ProjectTask[]>>(url).pipe(map((response: ApiResponse<ProjectTask[]>) => response.data));
    }

    createProjectTask(data: Record<string, unknown>): Observable<ProjectTask> {
        const url = 'items/project_tasks';

        return this.httpClient.post<ApiResponse<ProjectTask>>(url, data).pipe(map((response: ApiResponse<ProjectTask>) => response.data));
    }

    updateProjectTask(taskId: number, data: Record<string, unknown>): Observable<ProjectTask> {
        const url = `items/project_tasks/${taskId}`;

        return this.httpClient.patch<ApiResponse<ProjectTask>>(url, data).pipe(map((response: ApiResponse<ProjectTask>) => response.data));
    }

    deleteProjectTask(taskId: number): Observable<null> {
        const url = `items/project_tasks/${taskId}`;

        return this.httpClient.delete<null>(url);
    }

    getProjectBusinessAreas(): Observable<ProjectBusinessArea[]> {
        const url = `items/project_business_areas?fields=*.*&sort=name`;

        return this.httpClient
            .get<ApiResponse<ProjectBusinessArea[]>>(url)
            .pipe(map((response: ApiResponse<ProjectBusinessArea[]>) => response.data));
    }

    getProjectBoardAreas(): Observable<ProjectBoardArea[]> {
        const url = `items/project_board_areas?fields=*.*&sort=name`;

        return this.httpClient
            .get<ApiResponse<ProjectBoardArea[]>>(url)
            .pipe(map((response: ApiResponse<ProjectBoardArea[]>) => response.data));
    }

    getProjectTypologies(): Observable<ProjectTypology[]> {
        const url = `items/project_typologies?fields=*.*&sort=name`;

        return this.httpClient
            .get<ApiResponse<ProjectTypology[]>>(url)
            .pipe(map((response: ApiResponse<ProjectTypology[]>) => response.data));
    }
}
