import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { Component } from '@angular/core';

@Component({
    selector: 'ppm-ag-button-renderer',
    templateUrl: './ag-button-renderer.component.html',
    styleUrls: ['./ag-button-renderer.component.scss'],
})
export class AgButtonRendererComponent implements ICellRendererAngularComp {
    public params: any;
    public showIcon = true;

    agInit(params: any): void {
        this.params = params;
        // TODO: improve logic to hide icon on pinned rows
        if (this.params.api.getPinnedBottomRow(0)) {
            this.showIcon = this.params.node.id !== this.params.api.getPinnedBottomRow(0).id;
        }
    }

    onBtnClick() {
        this.params.clicked(this.params.node);
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}
